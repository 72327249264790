import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41')
];

export const server_loads = [];

export const dictionary = {
		"/(auth)": [16,[2]],
		"/(protected)/applications": [36,[3,10]],
		"/(protected)/(data-flow)/data-flow": [18,[3,4,5]],
		"/(protected)/(data-flow)/data-flow/add-custom-component": [19,[3,4]],
		"/(protected)/(data-flow)/data-flow/edit-custom-component/[instanceId]/[componentId]": [20,[3,4]],
		"/(protected)/(data-flow)/data-flow/edit-custom-component/[instanceId]/[componentId]/log": [21,[3,4]],
		"/(protected)/(data-flow)/data-flow/edit-custom-component/[instanceId]/[componentId]/metrics": [22,[3,4]],
		"/(protected)/(data-flow)/data-flow/edit-data-bridge/[instanceId]/[componentId]": [23,[3,4]],
		"/(protected)/(data-flow)/data-flow/edit-data-bridge/[instanceId]/[componentId]/log": [24,[3,4]],
		"/(protected)/(data-flow)/data-flow/edit-data-bridge/[instanceId]/[componentId]/metrics": [25,[3,4]],
		"/(protected)/home": [37,[3,11]],
		"/(protected)/(instances-view)/instances-view": [26,[3,6,7]],
		"/(protected)/(instances-view)/instances-view/add": [27,[3,6]],
		"/(protected)/(instances-view)/instances-view/edit/[instanceId]": [28,[3,6]],
		"/(protected)/(network-devices)/network-devices": [29,[3,8,9]],
		"/(protected)/(network-devices)/network-devices/add": [30,[3,8]],
		"/(protected)/(network-devices)/network-devices/edit/[instanceId]/[deviceId]": [31,[3,8]],
		"/(protected)/(network-devices)/network-devices/edit/[instanceId]/[deviceId]/protocol-converter/add": [32,[3,8]],
		"/(protected)/(network-devices)/network-devices/edit/[instanceId]/[deviceId]/protocol-converter/edit/[protocolConverterId]": [33,[3,8]],
		"/(protected)/(network-devices)/network-devices/edit/[instanceId]/[deviceId]/protocol-converter/edit/[protocolConverterId]/log": [34,[3,8]],
		"/(protected)/(network-devices)/network-devices/edit/[instanceId]/[deviceId]/protocol-converter/edit/[protocolConverterId]/metrics": [35,[3,8]],
		"/(protected)/network-view": [38,[3,12]],
		"/(auth)/register": [17,[2]],
		"/(protected)/settings": [39,[3,13]],
		"/(protected)/tag-browser": [40,[3,14]],
		"/(protected)/trace": [41,[3,15]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';